import React, { useState, useEffect } from 'react';
import axios from 'axios';
import JobList from './components/JobList';
import posthog from 'posthog-js';

posthog.init('phc_r3h6brhEZW4jTf0WTrIYeZ1S1ptOmN3AExWH2ZywBkZ', { api_host: 'https://us.i.posthog.com', person_profiles: 'always'});




function App() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      // Replace YOUR_BACKEND_ENDPOINT with your actual backend endpoint
      const response = await axios.get('https://mohammed-aldulaijan.online/json');
      const sortedJobs = response.data.sort((a, b) => new Date(b.list_date) - new Date(a.list_date));
      setJobs(sortedJobs);
    };
    fetchJobs();
  }, []);

  return (
    <div className="App">
      <JobList jobs={jobs} />
    </div>
  );
}

export default App;
